<script setup lang="ts">
import { onLaunch, onShow, onHide } from '@dcloudio/uni-app'
import { useCommonStore } from './store'
import { useUserStore } from '@/store/user'
import { usePayStore } from './store/pay'
const commonStore = useCommonStore()
const userStore = useUserStore()
const payStore = usePayStore()

useShareConfig({
  titleKey: 'wechatShareTitle_fd',
  descKey: 'wechatShareDesc_fd',
})

onLaunch(async () => {
  await commonStore.handleGetSystemProfile()
  payStore.resetPayStatus()
  if (userStore.isLogin) {
    commonStore.handleGetUserProfile()
  }
})

onShow((op) => {
  const extraData = op?.referrerInfo?.extraData
  // 支付信息
  if (payStore.onPaying && extraData) {
    payStore.payResult = extraData
  }
})

onError((err) => {
  console.log('全局错误捕获: ', err)
})

onHide(() => {})
</script>

<style lang="scss">
button::after {
  border: none;
}

swiper,
scroll-view {
  flex: 1;
  height: 100%;
  overflow: hidden;
  background-color: #f7f7f7;
}

image {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

// 单行省略，优先使用 unocss: text-ellipsis
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 两行省略
.ellipsis-2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

// 三行省略
.ellipsis-3 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

uni-page-body,
.wot-theme-light {
  height: 100%;
}

/* 全局主题样式定制 */
/// ConfigProviderThemeVars
:root,
page {
  --wot-primary-color: #ff5c01;
  --wot-button-primary-color: #ffffff;
  --wot-button-primary-bg-color: var(--wot-primary-color);
  --wot-tabs-nav-line-bg-color: var(--wot-primary-color);
  // switchActiveColor
  --wot-switch-active-color: var(--wot-primary-color);
  --wot-checkbox-checked-color: var(--wot-primary-color);
  --wot-input-icon-size: 19px;
}
:deep(.wd-navbar__content .wd-navbar__title) {
  font-weight: 400 !important;
}

.uni-toast__content {
  padding-left: 20rpx;
  padding-right: 20rpx;
}
// .z-paging-content-fixed,
// .uni-tabbar,
// .user-page {
//   min-width: 420px;
//   max-width: 800px;
//   margin: 0 auto;
// }
</style>
