import { http } from '@/utils/http'

const { get, post } = http

export const getSystemProfile = () => get('/settings/')

export const wxConfig = () => {
  const url = location.href.split('#')[0]
  return get('/wechat/config/?url=' + encodeURIComponent(url) + '&wx_name=' + '车队网公众号')
}

export const getAccessToken = () => {
  return post('/wechat/config/', { ajax_type: 'min_app_access_token' }).then((res) => {
    if (res.code === 200) {
      return res.data.min_app_access_token
    }
    return null
  })
}

export const getQrcode = (access_token, id) => {
  return post('/wechat/config/', {
    ajax_type: 'getwxacode',
    access_token,
    path: '/pages/user/user?invite_id=' + id,
  }).then((res) => {
    if (res.code === 200) {
      return res.data
    }
    return null
  })
}

export const getCustomQrcode = (access_token, path) => {
  return post('/wechat/config/', {
    ajax_type: 'getwxacode',
    access_token,
    path,
  }).then((res) => {
    if (res.code === 200) {
      return res.data
    }
    return null
  })
}

const baseUrl = import.meta.env.VITE_SERVER_BASEURL
export const shareRecord = (url: string, type?: number) => {
  let fullPath: string = url
  if (!url) {
    const pages = getCurrentPages()
    const curPage = pages[pages.length - 1]
    fullPath = curPage.$page ? curPage.$page.fullPath : curPage.route
    if (fullPath[0] !== '/') {
      fullPath += '/'
    }
    fullPath = baseUrl + '/#' + fullPath
  }
  post('/share-success/', { url: fullPath, type: type === 1 ? '好友' : type === 2 ? '朋友圈' : '' })
}

export const recordCall = (id, table_number) =>
  post<any>(`/fly-list-visits/record_call/`, { table_number, id })
