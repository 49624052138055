<template>
  <view
    @click="handleClick"
    :style="style"
    :class="[
      'card',
      'mb-20rpx',
      'border-rd-2',
      {
        'pt-60rpx!': overhead || praiseMark,
        'p-32rpx': !noPadding,
        [props.class]: !!props.class,
        'pc-card': deviceType.toLocaleLowerCase() === 'pc',
      },
    ]"
  >
    <view
      v-if="overhead || praiseMark"
      class="overhead h52rpx pt16rpx pr16rpx box-border flex justify-end items-center text-s"
    >
      <image src="@/static/feidan/overhead-icon.png" class="w40rpx h40rpx" />
      {{ praiseMark ? '好评单' : '置顶' }}
    </view>
    <slot name="header" />
    <slot />
    <view v-if="$slots.footer" class="footer">
      <slot name="footer" />
    </view>
  </view>
</template>

<script setup lang="ts">
const deviceType = uni.getSystemInfoSync().deviceType

const props = withDefaults(
  defineProps<{
    overhead?: boolean
    praiseMark?: boolean
    onClick?: any
    noPadding?: boolean
    style?: string
    class?: string
    state?: string
  }>(),
  {
    overhead: false,
    praiseMark: false,
    noPadding: false,
    class: '',
    style: '',
  },
)

const handleClick = () => {
  props.onClick?.()
}
</script>

<style scoped lang="scss">
.pc-card {
  width: 92% !important;
}
.card {
  position: relative;
  box-sizing: border-box;
  width: 686rpx;
  margin: 0 auto;
  margin-bottom: 20rpx;
  overflow: hidden;
  background-color: #ffffff;

  .overhead {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    font-weight: 500;
    color: #ff5c01;
    background: url('@/static/feidan/overhead.png');
    background-size: cover;
  }

  .footer {
    padding-top: 20rpx;
    margin-top: 19rpx;
    font-size: calc(--fontsize-s);
    color: #757575;
    border-top: 1rpx solid #f3f0f0;
  }
}
</style>
