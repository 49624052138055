<template>
  <view
    @click="handleBack"
    class="back-btn fixed right-38rpx bg-white w80rpx h80rpx border-rd-48rpx center flex-col"
    :style="`bottom: calc(100rpx + ${safeAreaInsets.bottom}px);`"
  >
    <image src="@img/back.png" class="w32rpx h32rpx" />
    <text class="text-c">返回</text>
  </view>
</template>

<script setup lang="ts">
const props = defineProps<{ url?: string; backTab?: boolean }>()
const { safeAreaInsets } = uni.getWindowInfo()

let params: any = {
  fromUrl: '',
  backToTab: null,
}

onLoad((query) => {
  params = query
})

const handleBack = () => {
  let url = props.url || params.fromUrl
  if (url) {
    url = decodeURIComponent(url)
  }
  const backTab = props.backTab || params.backToTab
  const pages = getCurrentPages()
  if (pages.length > 1) {
    uni.navigateBack()
  } else if (url) {
    if (backTab) {
      uni.switchTab({ url })
    } else if (pages.length > 1) {
      uni.navigateBack()
    } else {
      uni.redirectTo({ url })
    }
  } else {
    uni.switchTab({ url: '/pages/order/index' })
  }
}
</script>

<style scoped>
.back-btn {
  border: 1rpx solid rgba(39, 40, 34, 0.1);
}
</style>
